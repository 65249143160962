








import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    offset: {
      type: Number,
      default: 300
    }
  },
  setup(_props, { emit }) {
    function handleVisible(isVisible: boolean) {
      if (isVisible) {
        emit('scroll')
      }
    }

    return { handleVisible }
  }
})
