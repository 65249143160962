import { CIcon } from '~/icons/types'
export const ciQna: CIcon = {
  width: 512,
  height: 410.6,
  paths: [
    {
      d:
        'M476.8 129.7H301.9v-94c0-19.7-16-35.7-35.7-35.7H38.9C19.2 0 3.1 16 3.1 35.7v178.6c0 19.7 16 35.7 35.7 35.7h48.5v65c0 4.3 2.5 8.2 6.5 10 1.5.7 3 1 4.5 1 2.6 0 5.2-.9 7.3-2.7l83.5-73.2h62v63.8c0 17.7 14.4 32.1 32.1 32.1h65l70.6 61.9c2 1.8 4.6 2.7 7.3 2.7 1.5 0 3.1-.3 4.5-1 3.9-1.8 6.5-5.7 6.5-10V346h39.7c17.7 0 32.1-14.4 32.1-32.1V161.7c0-17.6-14.4-32-32.1-32zM185 228.1c-2.7 0-5.2 1-7.3 2.7l-68.3 59.9v-51.6c0-6.1-4.9-11-11-11H38.9c-7.6 0-13.7-6.2-13.7-13.7V35.7c0-7.6 6.2-13.7 13.7-13.7h227.3c7.6 0 13.7 6.2 13.7 13.7v178.6c0 7.6-6.2 13.7-13.7 13.7H185zm301.9 85.8c0 5.6-4.5 10.1-10.1 10.1h-50.7c-6.1 0-11 4.9-11 11v40.4l-55.5-48.6c-2-1.8-4.6-2.7-7.2-2.7h-69.1c-5.6 0-10.1-4.5-10.1-10.1v-64.4c16.4-3.3 28.8-17.7 28.8-35.1v-62.7h174.9c5.6 0 10.1 4.5 10.1 10.1v152z'
    },
    {
      d:
        'M151.2 179.1c-4.7 0-8.7 1.6-11.9 4.7-3.2 3.2-4.9 7.1-4.9 11.7 0 5.2 1.7 9.3 5 12.2 3.3 2.9 7.3 4.4 11.7 4.4 4.3 0 8.2-1.5 11.5-4.4 3.3-2.9 5-7 5-12.1 0-4.6-1.6-8.5-4.7-11.7-3.2-3.2-7.1-4.8-11.7-4.8zM182.1 51c-8.2-3.9-17.6-5.9-28.2-5.9-11.5 0-21.5 2.4-30.1 7.1-8.6 4.7-15.1 10.6-19.6 17.8-4.5 7.2-6.7 14.2-6.7 21.2 0 3.4 1.4 6.5 4.2 9.4 2.8 2.9 6.3 4.4 10.4 4.4 7 0 11.7-4.2 14.2-12.5 2.6-7.9 5.9-14 9.7-18 3.8-4.1 9.8-6.1 17.9-6.1 6.9 0 12.6 2 16.9 6.1 4.4 4 6.6 9 6.6 14.9 0 3-.7 5.8-2.2 8.4-1.4 2.6-3.2 4.9-5.3 7-2.1 2.1-5.5 5.2-10.2 9.3-5.4 4.7-9.6 8.8-12.8 12.2-3.2 3.4-5.7 7.4-7.6 11.9-1.9 4.5-2.9 9.9-2.9 16 0 4.9 1.3 8.6 3.9 11.1 2.6 2.5 5.8 3.7 9.7 3.7 7.4 0 11.7-3.8 13.1-11.5.8-3.6 1.4-6.1 1.8-7.6.4-1.4 1-2.9 1.7-4.3s1.9-3 3.4-4.7c1.5-1.7 3.5-3.7 6-6 9-8.1 15.3-13.8 18.8-17.3 3.5-3.4 6.5-7.5 9-12.2 2.5-4.7 3.8-10.2 3.8-16.4 0-7.9-2.2-15.3-6.7-22.1-4.4-6.6-10.7-12-18.8-15.9zm259.8 130.2H340.3c-6.1 0-11 4.9-11 11s4.9 11 11 11h101.6c6.1 0 11-4.9 11-11s-4.9-11-11-11zm0 42.8H340.3c-6.1 0-11 4.9-11 11s4.9 11 11 11h101.6c6.1 0 11-4.9 11-11s-4.9-11-11-11zm0 42.7H318.1c-6.1 0-11 4.9-11 11s4.9 11 11 11H442c6.1 0 11-4.9 11-11s-5-11-11.1-11z'
    }
  ],
  name: 'qna',
  type: 'brand'
}
