


















































































import Jumbotron from '~/components/car/classifieds/index/jumbotron/Jumbotron.vue'
import QuickSearchMobileDrawer from '~/components/car/categories-and-filters/QuickSearchMobileDrawer.vue'
import {
  DELETE_FETCHABLE_FEED,
  SET_FETCHABLE_FEEDS,
  SET_LANDING_CLASSIFIEDS_COUNT,
  SET_LANDING_UNREAD_MESSAGES_COUNT,
  SET_STATISTICS
} from '~/store/modules/shared/index/mutation-types'
import { mapState, mapGetters } from 'vuex'
import Feed from '~/components/car/classifieds/index/feeds/Feed.vue'
import FeedSidebar from '~/components/car/classifieds/index/feeds/FeedSidebar.vue'
import RecentFeed from '~/components/car/classifieds/index/feeds/RecentFeed.vue'
import { FeedTypes } from '~/models/user/types'
import { SET_PARKING_NOTIFICATIONS } from '~/store/modules/shared/parking/mutation-types'
import { USER_AGENT_NS } from '~/store/modules/shared/userAgent/state'
import { INDEX_NS } from '~/store/modules/shared/index/state'
import QnaPageCard from '~/components/car/qna/QnaPageCard.vue'
import LandingUserService from '~/services/user/LandingUserService'
import { USER_NS } from '~/store/modules/shared/user/state'
import { AccessToggleName } from '~/models/user/access-toggles'
import { CategoryId } from '~/models/category/types'
import {
  defineComponent,
  onBeforeUnmount,
  useStore
} from '@nuxtjs/composition-api'
import { useDep } from '~/compositions/dependency-container'

export default defineComponent({
  components: {
    Jumbotron,
    RecentFeed,
    Feed,
    FeedSidebar,
    QnaPageCard,
    QuickSearchMobileDrawer
  },
  async asyncData({ $dep, store }): Promise<void> {
    const userLandingService = $dep(LandingUserService)

    const [landing, count] = await Promise.all([
      userLandingService.getLanding(),
      userLandingService.getClassifiedsCount()
    ])
    const {
      fetchableFeeds,
      unreadMessages,
      parkingNotifications,
      statistics
    } = landing

    store.commit(`index/${SET_FETCHABLE_FEEDS}`, fetchableFeeds)
    store.commit(`index/${SET_LANDING_CLASSIFIEDS_COUNT}`, count)
    store.commit(`index/${SET_LANDING_UNREAD_MESSAGES_COUNT}`, unreadMessages)
    store.commit(`${INDEX_NS}/${SET_STATISTICS}`, statistics)
    store.commit(`parking/${SET_PARKING_NOTIFICATIONS}`, parkingNotifications)
  },
  setup() {
    const userLandingService = useDep(LandingUserService)
    const store = useStore()

    const unsubscribe = store.subscribeAction({
      after: async action => {
        if (!process.client) {
          return
        }
        if (action.type === `${USER_NS}/loadUser`) {
          const [landing, count] = await Promise.all([
            userLandingService.getLanding(),
            userLandingService.getClassifiedsCount()
          ])
          const {
            fetchableFeeds,
            unreadMessages,
            parkingNotifications,
            statistics
          } = landing

          store.commit(`index/${SET_FETCHABLE_FEEDS}`, fetchableFeeds)
          store.commit(`index/${SET_LANDING_CLASSIFIEDS_COUNT}`, count)
          store.commit(
            `index/${SET_LANDING_UNREAD_MESSAGES_COUNT}`,
            unreadMessages
          )
          store.commit(`${INDEX_NS}/${SET_STATISTICS}`, statistics)
          store.commit(
            `parking/${SET_PARKING_NOTIFICATIONS}`,
            parkingNotifications
          )
        }
      }
    })

    onBeforeUnmount(() => {
      unsubscribe()
    })
  },
  data: () => ({
    loadedFeeds: 1, // first feed is always loaded
    recentsLoaded: false,
    scrolledToFeeds: false,
    isQuickSearchOpen: false,
    indexSearchbarSelectedCategoryId: CategoryId.VEHICLES
  }),
  computed: {
    ...mapState(INDEX_NS, {
      fetchableFeeds: state => state.fetchableFeeds
    }),
    ...mapGetters(USER_AGENT_NS, {
      isPc: 'isPc',
      isTablet: 'isTablet'
    }),
    ...mapGetters(USER_NS, {
      userHasAccessTo: 'userHasAccessTo'
    }),
    userHasAccessToQuickSearch() {
      return this.userHasAccessTo(AccessToggleName.NEW_CATEGORIES_SEARCH_PAGE)
    },
    recentFeed() {
      if (this.fetchableFeeds) {
        return this.fetchableFeeds.find(f => {
          return f.type === FeedTypes.RECENT
        })
      }
      return null
    },
    userFeeds() {
      if (this.fetchableFeeds) {
        return this.fetchableFeeds.filter(f => {
          return f.type !== FeedTypes.RECENT
        })
      }
      return null
    }
  },
  mounted() {
    window.addEventListener('scroll', this.lazyLoadFeeds, false)
    if (window.location.hash.startsWith('#quick-search')) {
      this.openQuickSearch()
    }
    this.$nextTick(() => {
      this.lazyLoadFeeds()
    })
  },
  beforeDestroy() {
    this.destroyScrollListener()
  },
  methods: {
    triggerMenuClose() {
      if (this.$refs.drawer) {
        this.$refs.drawer.closeMenu()
      }
    },
    openQuickSearch(categoryId: CategoryId) {
      if (this.userHasAccessToQuickSearch) {
        switch (categoryId) {
          case CategoryId.PARTS:
            this.indexSearchbarSelectedCategoryId = CategoryId.PARTS
            this.isQuickSearchOpen = true
            break
          case CategoryId.XYMA:
            this.$router.push('/quick-search/xyma')
            break
          case CategoryId.PLOT:
            this.$router.push('/plot/search')
            break
          case CategoryId.JOBS:
            this.$router.push('/jobs')
            break

          default:
            this.indexSearchbarSelectedCategoryId = CategoryId.VEHICLES
            this.isQuickSearchOpen = true
            break
        }
      }
    },
    lazyLoadFeeds() {
      if (this.userFeeds.length && this.loadedFeeds < this.userFeeds.length) {
        for (let i = 0; i < this.userFeeds.length; i++) {
          const feed = this.$refs.userfeed[i]
          if (feed && feed.firstFetchTry === false && feed.isVisible()) {
            feed.fetchFeed()
            this.loadedFeeds++
          }
        }
      }

      if (!this.recentsLoaded) {
        if (this.isPc) {
          // lazy-load is not activated on pc, change this if you ever activate it there
          this.recentsLoaded = true
        } else {
          const recents = this.$refs.recentFeed
          if (
            recents &&
            recents.firstFetchTry === false &&
            recents.isVisible()
          ) {
            recents.fetchFeed()
            // initially request after 5secs and then fetchinterval takes control
            setTimeout(recents.fetchFeed, 5000)
            recents.startIntervals()
            this.recentsLoaded = true
          }
        }
      }

      if (!this.scrolledToFeeds && !this.isAboveFeedsVisible()) {
        // that means user has scrolled to the feeds area, send analytics event
        this.$analytics.recordEvent({
          namespace: 'n_index_scrolled_to_feeds',
          action: 'scroll'
        })
        this.scrolledToFeeds = true
      }

      if (this.loadedFeeds === this.userFeeds.length && this.recentsLoaded) {
        this.destroyScrollListener()
      }
    },
    destroyScrollListener() {
      window.removeEventListener('scroll', this.lazyLoadFeeds)
    },
    removeFeed(fetchUrl) {
      this.$store.commit(`index/${DELETE_FETCHABLE_FEED}`, fetchUrl)
      this.loadedFeeds--
      this.$nextTick(() => {
        this.lazyLoadFeeds()
      })
    },
    isAboveFeedsVisible() {
      if (document && this.$refs.recents) {
        // hide if window/tab is not visible
        if (typeof document.hidden !== 'undefined') {
          if (document.hidden) {
            return false
          }
        }
        let elem = null
        if (this.isPc) {
          elem = this.$refs.recents
        } else {
          elem = this.$refs.jumbotron.$el
        }
        const offset = 10
        let headerHeight = 0

        const desktopHeader = document.getElementById('header-section')
        if (desktopHeader) {
          headerHeight = desktopHeader.offsetHeight
        }

        const docViewTop = document.documentElement.scrollTop
        const docViewBottom = docViewTop + window.innerHeight

        let elemTop = headerHeight
        let offsetElem = elem
        if (offsetElem.offsetParent) {
          do {
            elemTop += offsetElem.offsetTop
            offsetElem = offsetElem.offsetParent
          } while (offsetElem)
        }

        if (elemTop < 0) {
          elemTop = headerHeight
        }

        const elemBottom = elemTop + elem.offsetHeight

        return (
          elemTop - offset <= docViewBottom &&
          elemBottom - headerHeight + offset >= docViewTop + headerHeight
        )
      }
      return false
    }
  }
})
