

















































































import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  setup() {
    const bannerUrl = computed(() => {
      return '/leasing-cars/'
    })

    return {
      bannerUrl
    }
  }
})
