var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CLink',{staticClass:"tw-w-full bg-gradient tw-relative tw-flex tw-rounded-xl tw-select-none tw-overflow-hidden tw-justify-center",attrs:{"href":_vm.bannerUrl,"draggable":"false"}},[_c('div',{staticClass:"tw-relative tw-flex tw-justify-center sm:tw-max-w-[677px] tw-items-center tw-w-full tw-h-full sm:tw-flex-row tw-flex-col",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('banner-click', {
        event: $event,
        path: _vm.bannerUrl
      })}}},[(_vm.$i18n.locale === 'el')?_c('div',{staticClass:"sm:tw-hidden tw-flex tw-flex-col tw-px-2 tw-ml-[8rem] tw-mt-4"},[_c('img',{staticClass:"tw-h-[25px] sm:tw-hidden tw-mb-1",attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/text_mobile1.svg')}}),_vm._v(" "),_c('img',{staticClass:"tw-h-[23px] sm:tw-hidden tw-ml-10",attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/text_mobile.svg')}})]):_c('div',{staticClass:"sm:tw-hidden tw-flex tw-flex-col tw-px-2 tw-ml-40 tw-mt-4 tw-z-10 en-mobile-text"},[_c('img',{staticClass:"tw-h-[25px] sm:tw-hidden tw-mb-1",attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/text_mobile1_eng.svg')}}),_vm._v(" "),_c('img',{staticClass:"tw-h-[37px] sm:tw-hidden tw-mt-2 tw-mb-1 tw-shrink-0 tw-relative tw-right-[-0.5rem]",attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/text_mobile2_eng.png')}}),_vm._v(" "),_c('img',{staticClass:"tw-h-[29px] sm:tw-hidden tw-shrink-0 tw-flex tw-relative tw-right-[-1.3rem]",attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/text_mobile3_eng.svg')}})]),_vm._v(" "),_c('img',{staticClass:"tw-max-h-[236px] sm:tw-h-[11.8rem] md:tw-top-6 sm:tw-top-[4vw] tw-relative sm:tw-ml-4  sm:tw-mb-0  sm:tw-mt-0 ",class:_vm.$i18n.locale === 'el'
          ? 'tw-mt-[-0.9rem] -tw-mb-8 tw-ml-[-3.6rem] tw-h-[9.375rem]'
          : 'sm:tw-block tw-hidden',attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/leasing-desktop-car.png')}}),_vm._v(" "),(_vm.$i18n.locale !== 'el')?_c('img',{staticClass:"tw-max-h-[236px] tw-h-[9.375rem] tw-relative tw-ml-[-3.6rem] -tw-mb-8 tw-mt-[-4.3rem] sm:tw-hidden en-mobile-car-img",attrs:{"draggable":"false","src":require('~/assets/img/banners/leasing/leasing-mobile-car.png')}}):_vm._e(),_vm._v(" "),_c('img',{staticClass:"tw-max-h-[8.125rem] lg:tw-h-[8.125rem] sm:tw-h-[25vw] tw-left-[-0.6rem] sm:tw-block tw-hidden",class:[_vm.$i18n.locale === 'el' ? 'sm:-tw-ml-4' : 'sm:tw-ml-2'],attrs:{"draggable":"false","src":_vm.$i18n.locale === 'el'
          ? require('~/assets/img/banners/leasing/text_desktop.svg')
          : require('~/assets/img/banners/leasing/text_desktop_eng.svg')}})])])}
var staticRenderFns = []

export { render, staticRenderFns }