


































import { ciQna } from '~/icons/source/brand/qna'
import CCard from '~/components/shared/configurable/card/CCard.vue'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: {
    CCard
  },
  props: {
    categoryId: { type: Number, default: null }
  },
  computed: {
    icons() {
      return {
        qna: ciQna
      }
    }
  }
})
