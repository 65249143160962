import { CIcon } from '~/icons/types'
export const ciCarBlog: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'm283.3 400.7-44.8 8.5c-1.7.3-3.5.5-5.2.5-7.4 0-14.6-2.9-19.9-8.3-6.6-6.6-9.5-16-7.8-25.2l8.4-44.8c1.1-5.6 3.7-10.7 7.8-14.7L398.2 140V81.8c0-35.1-28.6-63.7-63.7-63.7H63.7C28.6 18.1 0 46.7 0 81.8v348.4c0 35.1 28.6 63.7 63.7 63.7h270.8c35.1 0 63.7-28.6 63.7-63.8V292.6L298 392.9c-4 4-9.1 6.7-14.7 7.8zM77 107.8h220.1c10.4 0 18.8 8.4 18.8 18.8s-8.4 18.8-18.8 18.8H77c-10.4 0-18.8-8.4-18.8-18.8s8.4-18.8 18.8-18.8zm0 86.3h171.7c10.4 0 18.8 8.4 18.8 18.8.1 10.3-8.4 18.8-18.8 18.8H77c-10.4 0-18.8-8.4-18.8-18.8s8.4-18.8 18.8-18.8zm76.8 210.2H77c-10.4 0-18.8-8.4-18.8-18.8s8.4-18.8 18.8-18.8h76.8c10.4 0 18.8 8.4 18.8 18.8s-8.4 18.8-18.8 18.8zm12.9-86.3H77c-10.4 0-18.8-8.4-18.8-18.8s8.4-18.8 18.8-18.8h89.7c10.4 0 18.8 8.4 18.8 18.8s-8.4 18.8-18.8 18.8z'
    },
    {
      d:
        'm506 134-24.7-24.7c-5.7-5.7-14.2-7-21.2-4-2.1.9-4.2 2.3-5.9 4l-55.9 55.8-163.8 163.2c-2.8 2.7-4.6 6.2-5.3 10l-5.7 30.4c-1.2 6.3.8 12.6 5.3 17.1 3.6 3.7 8.5 5.6 13.5 5.6 1.2 0 2.4-.1 3.5-.3l30.4-5.8c3.8-.7 7.3-2.6 10-5.3l111.9-111.6L506 161.1c1.6-1.6 2.9-3.4 3.8-5.4 1.2-2.5 1.8-5.3 1.8-8.2 0-5.1-2-9.9-5.6-13.5z'
    }
  ],
  name: 'car-blog',
  type: 'solid'
}
