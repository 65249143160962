






















import {
  faParking,
  faPlusSquare,
  faSearch,
  faClock
} from '@fortawesome/free-solid-svg-icons'
import { FeedTypes } from '~/models/user/types'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { mapGetters } from 'vuex'
import { APP_NS } from '~/store/modules/shared/app/state'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    feedType: {
      type: String,
      required: false,
      default: null
    },
    url: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(APP_NS, {
      isPlot: 'isPlot'
    }),
    icons() {
      return {
        plusSquare: faPlusSquare,
        parking: faParking,
        search: faSearch,
        clock: faClock,
        youtube: faYoutube
      }
    },
    isUserClassifieds() {
      return this.feedType === 'user_classifieds'
    },
    isForParking() {
      return this.feedType === 'favorite_classifieds'
    },
    isYoutubeFeed() {
      return this.feedType === FeedTypes.YOUTUBE
    },
    isRecent() {
      return (
        this.feedType === 'recent_user_search' ||
        this.feedType === 'recent_viewed_classifieds'
      )
    },
    isNormalSearch() {
      return (
        (!this.isUserClassifieds && !this.isForParking && !this.isRecent) ||
        this.feedType === null
      )
    },
    labelToShow() {
      if (this.isUserClassifieds) {
        return this.$t('new classified')
      }
      if (this.isForParking) {
        return 'Parking'
      }
      return this.$t('more')
    },
    iconToShow() {
      if (this.isYoutubeFeed) {
        return this.icons.youtube
      }
      if (this.isUserClassifieds) {
        return this.icons.plusSquare
      }
      if (this.isForParking) {
        return this.icons.parking
      }
      if (this.isRecent) {
        return this.icons.clock
      }
      return this.icons.search
    },
    computedUrl() {
      if (this.isUserClassifieds) {
        return this.$dep(LegacyUrlService).getNewClassifiedUrl()
      }
      return this.url
    }
  },
  methods: {
    hrefClick(e) {
      if (!this.isUserClassifieds) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('clicked', e)
      }
    }
  }
})
