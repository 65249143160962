import { render, staticRenderFns } from "./IndexCategoryIcon.vue?vue&type=template&id=5836a1e5&scoped=true&"
import script from "./IndexCategoryIcon.vue?vue&type=script&lang=ts&"
export * from "./IndexCategoryIcon.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5836a1e5",
  null
  
)

export default component.exports