import { CIcon } from '~/icons/types'
export const ciVehiclesInactive: CIcon = {
  width: 512,
  height: 512,
  paths: [
    {
      d:
        'M164.4 265c-17.5-4.6-38.8-7.8-52-7.8-9.1 0-14.3 3.8-17.1 6.9-4.3 4.9-5.9 11.6-4.8 19.9 1.9 14.8 11.9 28.1 26.5 35.6 12.5 6.4 24.5 9.6 35.5 9.6 29.1 0 51.9-15.8 51.9-36-.1-12.2-12.4-20.9-40-28.2zm-11.9 43.2c-7.5 0-16.5-2.5-25.9-7.3-8.5-4.3-14.2-11.6-15.2-19.6-.2-1.5-.2-2.5-.1-3.1.3 0 .7-.1 1.2-.1 10 0 27.1 2.4 41.7 5.9 18.1 4.3 26.3 8.6 28.9 10.7-1.8 6.1-13.4 13.5-30.6 13.5z'
    },
    {
      d:
        'M399.6 257.2c-13.1 0-34.5 3.2-52 7.8-27.6 7.3-40 16-40 28.2 0 20.2 22.8 36 51.8 36 11 0 22.9-3.2 35.5-9.6 14.7-7.4 24.6-20.7 26.5-35.6 1.1-8.3-.5-15-4.8-19.9-2.7-3.2-7.9-6.9-17-6.9zm1.1 24.1c-1 8-6.7 15.3-15.2 19.6-9.4 4.8-18.4 7.3-25.9 7.3-17.3 0-28.8-7.5-30.6-13.4 2.7-2.1 10.8-6.4 28.9-10.7 14.6-3.5 31.8-5.9 41.7-5.9.5 0 .9 0 1.2.1 0 .5.1 1.5-.1 3z'
    },
    {
      d:
        'M475 160.8h-33.4l-8.7-39.6-1.1-1.7C391.4 60 343.4 59.7 256.5 59.7h-1c-86.9 0-134.9.2-175.2 59.8l-1.1 1.7-8.8 40.2v-.7H37c-19.7 0-35.7 20-35.7 44.6 0 15.1 9.9 26.9 22.6 26.9H48l-14.3 99.6v.8c0 19.4 7.7 37.8 21.7 51.9 3.3 3.4 6.9 6.3 10.7 9v25.8c0 32.9 28.9 32.9 44.4 32.9s44.4 0 44.4-32.9v-13.1H361v13.1c0 32.9 28.9 32.9 44.4 32.9s44.4 0 44.4-32.9v-28.6c2.4-1.9 4.8-4 7-6.2 14-14.1 21.7-32.6 21.7-51.9v-.8L464 232.3h24.1c12.7 0 22.6-11.8 22.6-26.9 0-24.6-16-44.6-35.7-44.6zM98.8 129.7c33.9-48.8 72.9-48.9 157.2-48.9s123.3.1 157.2 48.9l18 82.1H80.8l18-82.1zm-74.5 81.6c-.6-.5-2-2.5-2-5.9 0-13.9 7.8-23.6 14.7-23.6h28.8l-6.5 29.5h-35zm109.4 208.1c0 8.2 0 11.9-23.4 11.9S87 427.5 87 419.4v-15.8c6 1.7 12.2 2.7 18.3 2.7h28.4v13.1zm295 0c0 8.2 0 11.9-23.4 11.9s-23.4-3.7-23.4-11.9v-13.1h24.6c7.5 0 14.9-1.3 22.1-3.9l.1 17zm-22.1-34.1H105.4c-26.8 0-50.3-24.2-50.7-51.9l14.5-100.6H443l14.5 100.6c-.6 27.7-24.1 51.9-50.9 51.9zm81.1-174h-35.1l-6.5-29.5H475c7 0 14.7 9.7 14.7 23.6 0 3.3-1.3 5.3-2 5.9z'
    }
  ],
  name: 'vehicles-inactive',
  type: 'regular'
}
