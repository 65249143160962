





























































import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import { defineComponentTranslations } from '~/utils/i18n'
import { useDep } from '~/compositions/dependency-container'
import { useNamespacedStore } from '~/compositions/store'
import { CategoryId } from '~/models/category/types'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { SET_SEARCH_TYPE } from '~/store/modules/shared/classifieds/search/mutation-types'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import { ciLayout } from '~/icons/source/solid/layout'
import CIcon from '~/components/shared/configurable/CIcon.vue'
import CLink from '~/components/shared/configurable/link/CLink.vue'
import CDynamicIcon from '~/components/shared/configurable/icon/CDynamicIcon.vue'
import IndexSearchbar from '~/components/car/classifieds/index/jumbotron/IndexSearchbar.vue'
import IndexSlider from '~/components/car/IndexSlider.vue'
import CPosting from '~/components/shared/configurable/ad/CPosting.vue'

export default defineComponent({
  components: {
    CIcon,
    CLink,
    CDynamicIcon,
    CPosting,
    IndexSlider,
    IndexSearchbar
  },
  setup(_, { emit }) {
    const legacyUrlService = useDep(LegacyUrlService)
    const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
      USER_AGENT_NS
    )
    const { commit: classifiedSearchCommit } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )

    const offerBtnLink = legacyUrlService.offerBtnLink()
    const offerBtnIcon = legacyUrlService.offerBtnIcon()
    const offerBtnText = computed(() =>
      legacyUrlService.offerBtnText(isMobile.value)
    )
    const isPc = computed(() => userAgentGetters('isPc'))
    const isMobile = computed(() => userAgentGetters('isMobile'))
    const searchHref = computed(() => {
      return isPc.value ? desktopHrefLink.value : '/quick-search/'
    })

    const desktopHrefLink = ref('/quick-search/vehicles/cars')
    const selectedSearchbarCategory = ref(CategoryId.VEHICLES)

    const configureSearchTargetLink = (categoryId: CategoryId) => {
      selectedSearchbarCategory.value = categoryId
      switch (categoryId) {
        case CategoryId.PARTS:
          desktopHrefLink.value = '/quick-search/parts/for-cars'
          break
        case CategoryId.XYMA:
          desktopHrefLink.value = '/quick-search/xyma'
          break
        case CategoryId.PLOT:
          desktopHrefLink.value = '/quick-search/plot'
          break
        case CategoryId.JOBS:
          desktopHrefLink.value = '/jobs'
          break
        default:
          desktopHrefLink.value = '/quick-search/vehicles/cars'
          break
      }
    }
    const handleMobileQuickSearchOpener = (event: Event) => {
      if (selectedSearchbarCategory.value === CategoryId.PLOT) {
        classifiedSearchCommit(SET_SEARCH_TYPE, true)
      }
      if (isMobile.value) {
        event.preventDefault()
        emit('open-quick-search', selectedSearchbarCategory.value)
      }
    }
    return {
      ciLayout,
      desktopHrefLink,
      selectedSearchbarCategory,
      searchHref,
      offerBtnLink,
      offerBtnIcon,
      offerBtnText,
      isMobile,
      handleMobileQuickSearchOpener,
      configureSearchTargetLink
    }
  },
  i18n: defineComponentTranslations({
    'free search & classifieds posting': {
      en: 'Free search & classifieds posting',
      el: 'Δωρεάν αναζήτηση & καταχώριση αγγελιών'
    }
  })
})
