



































import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { mapActions, mapGetters } from 'vuex'
import NotificationCenter from '~/components/shared/configurable/notification-center/NotificationCenter.vue'
import { USER_NS } from '~/store/modules/shared/user/state'
import { APP_NS } from '~/store/modules/shared/app/state'
import { DropdownPosition } from '~/models/app/dropdown'

export default defineComponent({
  components: {
    NotificationCenter
  },
  props: {
    disableDropdown: {
      type: Boolean,
      default: false
    },
    noArrow: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String as PropType<DropdownPosition>,
      default: 'auto'
    }
  },
  computed: {
    ...mapGetters(APP_NS, {
      isCar: 'isCar',
      isPlot: 'isPlot'
    }),
    ...mapGetters(USER_NS, {
      isGuest: 'isGuest'
    })
  },
  methods: {
    ...mapActions(USER_NS, ['fetchNotificationCenterData']),
    handleShow() {
      this.$refs.notificationCenter.clearData()
      this.fetchNotificationCenterData({ page: 1 })
    }
  }
})
