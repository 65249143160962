

















import { defineComponent } from '@nuxtjs/composition-api'
import CLink from '~/components/shared/configurable/link/CLink.vue'

export default defineComponent({
  props: {
    href: {
      type: String,
      default: null
    },
    to: {
      type: [Object, String],
      default: null
    },
    target: {
      type: String,
      default: '_self'
    },
    isQuickSearch: {
      type: Boolean,
      default: false
    }
  },
  components: { CLink }
})
