import { AxiosInstance } from 'axios'
import { inject } from 'tsyringe'
import { httpToken } from '~/constants/dependency-injection/tokens'
import { containerScoped } from '~/decorators/dependency-container'
import {
  formatFetchableFeed,
  formatLandingStatistics
} from '~/services/user/formatters'
import { LandingClassifiedsCount, LandingResult } from '../../models/user/types'
import { invalidBodyError } from '../errors'
import { toCamelCase } from '~/utils/object'

@containerScoped()
export default class LandingUserService {
  constructor(@inject(httpToken) private http: AxiosInstance) {}

  async getLanding(feedParam: string = ''): Promise<LandingResult> {
    const response = await this.http.get('/api/user/landing/' + feedParam)
    const body = response.data
    if (!(body && body.data && body.data.feeds)) {
      throw invalidBodyError(body)
    }

    return this.formatLandingResult(body.data)
  }

  async getClassifiedsCount(): Promise<LandingClassifiedsCount> {
    const response = await this.http.get('/api/classifieds/count/')
    const body = response.data
    if (!(body && body.data && body.data.count)) {
      throw invalidBodyError(body)
    }
    return body.data.count
  }

  formatLandingResult(r: any) {
    return {
      fetchableFeeds: r.feeds.map(formatFetchableFeed),
      unreadMessages: r.messages ? r.messages.unread_messages_count : 0,
      parkingNotifications:
        r.notifications && r.notifications.unseen_count
          ? r.notifications.unseen_count
          : 0,
      quickSearches: r.quick_searches
        ? toCamelCase(r.quick_searches)
        : undefined,
      statistics: r.statistics && formatLandingStatistics(r.statistics)
    }
  }
}
