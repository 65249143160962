var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CLink',{staticClass:"audits-banner tw-w-full tw-bg-grey-300 tw-relative tw-flex tw-rounded-xl tw-select-none tw-overflow-hidden tw-justify-center",style:(("--audits-banner-bg: url(" + (_vm.$s(
      '/nuxt-static/img/audits/banner/bg_desktop.png?v=2'
    )) + ");\n      --audits-banner-bg-mobile: url(" + (_vm.$s(
        '/nuxt-static/img/audits/banner/bg_mobile.png?v=2'
      )) + ");")),attrs:{"to":{ name: '__landing_pages_audits' },"draggable":"false"}},[_c('CImg',{staticClass:"tw-absolute sm:tw-left-0 tw-opacity-50 tw-left-[-30px] tw-top-0",attrs:{"draggable":"false","src":_vm.$s('/nuxt-static/img/audits/banner/bg_lines_L.png')}}),_vm._v(" "),_c('CImg',{staticClass:"tw-absolute tw-right-0 tw-opacity-50 tw-bottom-0 max-sm:tw-w-[28%]",attrs:{"draggable":"false","src":_vm.$s('/nuxt-static/img/audits/banner/bg_lines_R.png')}}),_vm._v(" "),_c('div',{staticClass:"tw-relative tw-w-full tw-h-full tw-flex tw-justify-start tw-items-end tw-pb-6 max-[350px]:tw-ml-2 tw-ml-6 sm:tw-ml-12 sm:tw-pb-0 sm:tw-items-center ",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.$emit('banner-click', {
        event: $event,
        path: _vm.$router.resolve({ name: '__landing_pages_audits' }).href
      })}}},[_c('div',{staticClass:"sm:-tw-mt-2"},[_c('h1',{staticClass:"tw-flex sm:tw-text-5xl tw-text-3xl tw-mb-0 tw-leading-[normal] tw-text-white tw-font-bold tw-flex-col tw-max-w-[17rem] sm:tw-max-w-[32rem] tw-items-center"},[_vm._v("\n        "+_vm._s(_vm.$t('audit_title'))+"\n      ")]),_vm._v(" "),_c('button',{staticClass:"audits-landing-btn tw-text-base tw-h-7 tw-px-3 sm:tw-text-xl sm:tw-leading-none sm:tw-font-medium sm:tw-px-8 sm:tw-h-[2.5rem] sm:tw-mt-2 tw-mt-2 tw-font-medium"},[_vm._v("\n        "+_vm._s(_vm.$t('for_buyers_and_sellers'))+"\n      ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }