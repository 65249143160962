import { CIcon } from '~/icons/types'
export const ciElectricLeaf: CIcon = {
  name: 'electric-leaf',
  type: 'solid',
  width: 24,
  height: 24,
  paths: [
    {
      d:
        'M3.87 8.52c-.53-.77-.81-1.66-1.83-2.17.6.72 1.01 1.35 1.24 2.09.14.46.05.66-.46.62C1.64 8.97.72 8.17.47 7.01L.15 5.54 0 4.85l.47.12c2.49.57 3.56 1.65 3.4 3.55zm14.59-3.13c-1.03 1.6-2.89 4.5-3.34 5.26-.36.61-.22.77.48.79 1.09.03 2.18.09 3.26.14.49.02.81.14.42.64l-6.66 8.31c-.03.03-.08.08-.13.1-.1.05-.12.03-.14.03-.04-.01-.05-.05-.04-.09.49-1.35.96-2.65 1.44-3.94.21-.57.41-1.15.66-1.71.3-.68 0-.89-.72-.91-1.15-.03-2.3-.11-3.45-.14-.68-.02-.95-.19-.63-.85.82-1.66 3.89-8.22 4.66-9.87.11-.23.35-.36.59-.33l.09.01c3.12.38 6.02 2.18 7.7 5.11 2.83 4.95 1.03 11.37-3.96 14.13-4.91 2.71-11.12.98-13.92-3.9-1.3-2.27-1.71-4.86-1.14-7.22.05-.19.11-.41.2-.73.13-.45.33-.89.55-1.33.25-.49.28-.94.23-1.44-.2-2.12.34-3.99 2.12-5.29C8.61.79 10.8.53 13.11.69c-.79.85-.87 1.84-.93 2.88-.1 1.78-.45 3.48-2.08 4.59-.93.65-2 .82-3.12.78-.48-.02-.44-.29-.33-.61.33-.93.83-1.76 1.37-2.57.09-.13 1.07-1.58 1.07-1.58-1.92 1.7-3.25 3.85-3.93 6.09l-.07.22c-.68 2.28-.46 4.82.81 7.04a9 9 0 0 0 12.3 3.35c4.31-2.47 5.81-7.98 3.35-12.3a9.01 9.01 0 0 0-3.09-3.19z',
      fill: 'currentColor'
    }
  ]
}
