













import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api'
import { facetProps } from '~/constants/quick-search'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import { Option } from '~/models/shared/types'
import { useQuickSearchParams } from '~/compositions/quick-search/useQuickSearchParams'
import { useI18n } from '~/compositions/i18n'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import { useNamespacedStore } from '~/compositions/store'
import {
  QUICK_SEARCH_NS,
  QuickSearchState
} from '~/store/modules/shared/quickSearch/state'

export default defineComponent({
  components: { CCustomSelect },
  props: { ...facetProps },
  setup(props) {
    const { searchables, urlArg } = toRefs(props)

    const { changeParam } = useQuickSearchParams()
    const { t } = useI18n()
    const { state: quickSearchState } = useNamespacedStore<QuickSearchState>(
      QUICK_SEARCH_NS
    )

    const isLoading = computed(() => quickSearchState.loading)
    const selectedCategory = ref(null)

    const options = computed<Option[]>(() => {
      return [...searchables.value?.map(mapBackendSearchableToOption)]
    })

    function onSelectChange(value: any) {
      selectedCategory.value = value
      changeParam(urlArg.value, value)
    }

    const hasSearchables = computed(() => Boolean(searchables.value?.length))

    const tooltipText = computed(() => {
      if (!hasSearchables.value) {
        return t('please select an offer type first')
      }
      return ''
    })

    return {
      isLoading,
      options,
      selectedCategory,
      hasSearchables,
      onSelectChange,
      tooltipText
    }
  }
})
