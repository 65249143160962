import { CIcon } from '~/icons/types'
export const ciUserPlus: CIcon = {
  name: 'user-plus',
  type: 'regular',
  width: 512,
  height: 504.8,
  paths: [
    {
      d:
        'M222.7 252.4c69.7 0 126.2-56.5 126.2-126.2S292.4 0 222.7 0 96.5 56.5 96.5 126.2 153 252.4 222.7 252.4zm0-220.8c52.2 0 94.7 42.5 94.7 94.7S274.9 221 222.7 221 128 178.4 128 126.2s42.5-94.6 94.7-94.6zm132.9 441.7H49.2c-8.7 0-15.8-7.1-15.8-15.8v-41c0-55.7 45.3-101 101-101 19.3 0 38.6 15.8 88.3 15.8 49.7 0 69-15.8 88.3-15.8 11 0 21.6 1.8 31.6 5 6.4 2.1 13-2.7 13-9.4v-12.3c0-4.3-2.9-8.3-7.1-9.5-11.9-3.5-24.5-5.4-37.5-5.4-28.3 0-41.9 15.8-88.3 15.8s-60-15.7-88.4-15.7C61.2 284 1.8 343.3 1.8 416.5v41c0 26.1 21.2 47.3 47.3 47.3h306.5c5.4 0 9.9-4.4 9.9-9.9v-11.8c-.1-5.4-4.5-9.8-9.9-9.8z',
      fill: 'currentColor'
    },
    {
      d:
        'M502 378.6h-82.8v-82.8c0-6.5-5.3-11.8-11.8-11.8h-7.9c-6.5 0-11.8 5.3-11.8 11.8v82.8h-82.8c-6.5 0-11.8 5.3-11.8 11.8v7.9c0 6.5 5.3 11.8 11.8 11.8h82.8V493c0 6.5 5.3 11.8 11.8 11.8h7.9c6.5 0 11.8-5.3 11.8-11.8v-82.8H502c6.5 0 11.8-5.3 11.8-11.8v-7.9c0-6.6-5.3-11.9-11.8-11.9z',
      fill: 'currentColor'
    }
  ]
}
