









import { defineComponent, toRefs } from '@nuxtjs/composition-api'
import CLocationAutocompleteInput from '~/components/shared/configurable/location/CLocationAutocompleteInput.vue'
import { facetProps } from '~/constants/quick-search'
import { useQuickSearchParams } from '~/compositions/quick-search/useQuickSearchParams'
import { GeocodingSearchLocation } from '~/models/location/geocoding'
import { createSearchGeolocationString } from '~/utils/geolocation'

export default defineComponent({
  props: { ...facetProps },
  components: { CLocationAutocompleteInput },
  setup(props) {
    const { urlArg } = toRefs(props)

    const { changeParam } = useQuickSearchParams()
    function onLocationsChange(geolocations: GeocodingSearchLocation[]) {
      changeParam(urlArg.value, geolocations.map(createSearchGeolocationString))
    }

    return { onLocationsChange }
  }
})
