import { useNamespacedStore } from '~/compositions/store'
import {
  QUICK_SEARCH_NS,
  QuickSearchState
} from '~/store/modules/shared/quickSearch/state'

export function useQuickSearchParams() {
  const { dispatch } = useNamespacedStore<QuickSearchState>(QUICK_SEARCH_NS)

  function changeParam(name: string, value: any) {
    return dispatch('changeParamAndReloadData', { name, value })
  }
  function changeParams(params: Array<{ name: string; value: any }>) {
    return dispatch('changeParamsAndReloadData', params)
  }

  return { changeParam, changeParams }
}
