





























import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api'
import CCustomSelect from '~/components/shared/configurable/form/select/custom/CCustomSelect.vue'
import { facetProps } from '~/constants/quick-search'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { mapBackendSearchableToOption } from '~/services/search/formatters'
import CCustomSelectOptionWithCount from '~/components/shared/configurable/form/select/custom/option/CCustomSelectOptionWithCount.vue'
import {
  QUICK_SEARCH_NS,
  QuickSearchState
} from '~/store/modules/shared/quickSearch/state'
import { useNamespacedStore } from '~/compositions/store'
import { useQuickSearchParams } from '~/compositions/quick-search/useQuickSearchParams'
import {
  USER_AGENT_NS,
  UserAgentState
} from '~/store/modules/shared/userAgent/state'
import CLink from '~/components/shared/configurable/link/CLink.vue'

export default defineComponent({
  props: { ...facetProps },
  components: {
    CCustomSelect,
    CCustomSelectOptionWithCount,
    CLink
  },
  setup(props) {
    const { searchables, urlArg } = toRefs(props)

    const { state: quickSearchState } = useNamespacedStore<QuickSearchState>(
      QUICK_SEARCH_NS
    )
    const { getters: userAgentGetters } = useNamespacedStore<UserAgentState>(
      USER_AGENT_NS
    )
    const { changeParams } = useQuickSearchParams()

    const selectedValue = ref()

    const options = computed(() =>
      searchables.value.map(mapBackendSearchableToOption)
    )
    const newClassifiedUrl = computed(
      () => quickSearchState.targets.new?.seoUrl || ''
    )
    const isMobile = computed(() => userAgentGetters('isMobile'))

    function onChange(value: any) {
      selectedValue.value = value
      changeParams([
        { name: urlArg.value, value },
        // Also clear the category when changing offer types
        { name: 'category', value: null }
      ])
    }

    const icons = {
      plus: faPlus
    }

    return {
      selectedValue,
      options,
      newClassifiedUrl,
      icons,
      onChange,
      isMobile
    }
  }
})
