




















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { CIcon } from '~/icons/types'
import { CategoryId } from '~/models/category/types'

export default defineComponent({
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: Object as PropType<CIcon>,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    catId: {
      type: Number as PropType<CategoryId>,
      required: true
    }
  },
  computed: {
    iconWidthClass() {
      if (this.catId === CategoryId.VEHICLES) {
        return 'md:!tw-w-[2.5rem] !tw-w-[2rem]'
      }
      if (this.catId === CategoryId.PLOT) {
        return 'md:!tw-w-[4.5rem] !tw-w-[3.5rem]'
      }
      return '!tw-w-auto tw-flex-1'
    },
    iconClasses(): string {
      let classes =
        "tw-whitespace-nowrap tw-cursor-pointer tw-flex tw-shrink-0 tw-flex-col tw-items-center tw-p-1 tw-pb-[0.35rem] tw-transition-colors tw-text-grey-600 md:hover:tw-text-grey-700 tw-relative after:tw-content-[''] after:tw-transition-all md:after:tw-bg-grey-400 after:tw-opacity-100 after:tw-bottom-0 after:tw-h-[0.2rem] after:tw-absolute after:tw-left-0 after:tw-top-full after:tw--mt-[0.2rem] after:tw-w-full after:tw-scale-x-0 md:hover:after:tw-scale-x-100 "
      if (this.active) {
        classes +=
          ' !tw-text-blue-800 after:tw-scale-x-100 after:!tw-bg-blue-800'
      }
      return classes
    }
  }
})
