


















import { CustomLinkFeed } from '~/models/user/types'
import { defineComponent, PropType } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    customLinkData: {
      type: Object as PropType<CustomLinkFeed>,
      required: true
    },
    showLink: {
      type: Boolean,
      required: false,
      default: true
    },
    rowStyle: {
      type: Boolean,
      required: false,
      default: false
    },
    feedLabel: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    customLinkUrl() {
      return this.customLinkData.targetUrl
    }
  },
  mounted() {
    this.$emit('image-load')
  },
  methods: {
    hrefClick(e) {
      e.preventDefault()
      e.stopPropagation()
      if (this.showLink) {
        this.linkClicked(e)
      }
      return false
    },
    linkClicked(_evt) {
      this.$analytics.recordEvent({
        namespace: 'n_index_feed_custom_link',
        action: 'click',
        label: this.feedLabel + ' (' + this.title + ')'
      })

      return window.open(this.customLinkUrl)
    }
  }
})
