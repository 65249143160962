import { CIcon } from '~/icons/types'

export const ciSearch: CIcon = {
  width: '28',
  height: '32',
  paths: [
    {
      d:
        'M12.35 23.588C5.918 23.588.64 18.309.64 11.876S5.917 0 12.35 0c6.434 0 11.712 5.278 11.712 11.711 0 6.433-5.114 11.877-11.712 11.877Zm0-21.939C6.908 1.65 2.29 6.268 2.29 11.876c0 5.609 4.618 10.062 10.062 10.062 5.608 0 10.061-4.618 10.061-10.062 0-5.443-4.453-10.227-10.061-10.227Z',
      fill: '#1976D2'
    },
    {
      d:
        'm20.103 19.464 5.773 8.083s1.32 1.814-.165 2.968c-.33.33-1.98 1.155-2.804 0-2.144-2.639-5.938-8.742-5.938-8.742s.99-.495 1.65-.99c.66-.494 1.484-1.319 1.484-1.319Z',
      fill: '#92D3F2'
    },
    {
      d:
        'M24.227 32c-.66 0-1.32-.33-1.98-.99-2.144-2.639-5.938-8.577-6.103-8.742l-.33-.825.825-.33s.99-.494 1.65-.99c.494-.33 1.32-1.154 1.32-1.154l.659-.66 6.433 8.743c.66.99 1.32 2.804-.33 4.123-.495.33-1.32.825-2.144.825Zm-6.103-9.897c1.154 1.65 3.794 5.938 5.443 7.918.66.824 1.65 0 1.65 0 .824-.66.164-1.65 0-1.815l-5.279-7.258c-.33.165-.66.495-.825.66-.33 0-.66.33-.99.495Z',
      fill: '#1976D2'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'search',
  type: 'duotone'
}
