import { CIcon } from '~/icons/types'
export const ciLayout: CIcon = {
  name: 'layout',
  type: 'solid',
  width: 512,
  height: 512,
  paths: [
    { d: 'M0 0h206.2v275.3H0zm0 352.3h206.2V512H0z', fill: 'currentColor' },
    { d: 'M305.8 236.7H512V512H305.8z', fill: 'currentColor' },
    { d: 'M305.8 0H512v159.7H305.8z', fill: 'currentColor' }
  ]
}
